import { flatMap, flatMapDeep } from 'lodash';

const detailsFlattener = (vendor) => {
	const details = {};
	const extraFilters = [
		'c6046f9a-5780-4b76-8ace-731ff1b7ae62', // Price Range
		'c1b91e1f-a8ac-4896-9e7f-fcbbad603c5b', // Venue Amenities
		'd152eee4-6a3f-4116-9226-8a977d274b85', // Starting Price Range
	];

	// filters out price range, venue amenities, and starting price range
	const facets = vendor.facets.filter(
		(facet) => !extraFilters.includes(facet.id),
	);

	for (const facet of facets) {
		const facetNameGetter = (fct) => {
			if (fct.facets?.length) {
				return [fct.name, ...fct.facets.map(facetNameGetter)];
			}
			return fct.name;
		};

		details[facet.name] = flatMapDeep(facet.facets, facetNameGetter);
	}

	return details;
};

const findMatchingFacets = (allFacets, facetIds) => {
	const facetNameFlattener = (facet) => {
		const shouldBeIncluded = facetIds.includes(facet.id);
		const hasNarrowerTerms = facet.narrower.length;

		if (shouldBeIncluded) {
			return facet.pref_label;
		}
		if (hasNarrowerTerms) {
			return facet.narrower.map(facetNameFlattener);
		}

		return false;
	};

	return flatMap(allFacets, facetNameFlattener).filter((f) => f);
};

const hasDetails = (decoratedVendor) => {
	const vendorFacets = Object.keys(detailsFlattener(decoratedVendor));

	return (
		decoratedVendor.amenities.length > 0 ||
		vendorFacets.length > 0 ||
		decoratedVendor.awards.length > 0 ||
		decoratedVendor.designers.length > 0
	);
};

export default { detailsFlattener, findMatchingFacets, hasDetails };
