import { useStorefrontBookingTest1 } from '@feature-flags/hooks/Storefront/useStorefrontBookingTest1';
import { isUIReadabilityEnhancementsSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppSelector } from '@redux/hooks';
import { Button } from '@xo-union/tk-component-buttons';
import { IconButton } from '@xo-union/tk-component-icons';
import { Subhead } from '@xo-union/tk-ui-typography';
import cx from 'classnames';
import React, { type CSSProperties, forwardRef } from 'react';
import { useHistory } from 'react-router';
import type { StickyChildArgs } from 'react-sticky';
import type { Decorated } from 'types/vendor';
import { StartConversation } from '../../../components/StartConversation/StartConversation';
import SocialSharing from '../../../components/social_sharing';
import { useTrackBooking } from '../../../hooks/use-track-booking';
import { useStorefrontFeatureScannability } from '../../../hooks/useStorefrontFeatureScannability';
import FavoritesButton from '../../vendor_info/components/favorites_button';
import Styles from '../styles.scss';
import type { NavItemElements } from '../types';
import { NavMenuItems } from './NavMenuItems';
import NavItem from './nav_item';

interface NavMenuProps extends Pick<StickyChildArgs, 'style' | 'isSticky'> {
	children: NavItemElements;
}

export const NavMenu = forwardRef<HTMLUListElement, NavMenuProps>(
	(props: NavMenuProps, ref) => {
		const { children, style, isSticky } = props;
		const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
		const viewport = useAppSelector((state) => state.viewport);
		const membership = useAppSelector((state) => state.membership);
		const styles: CSSProperties = {
			...style,
			zIndex: 2,
		};

		const { width, left, ...enhancementStyles } = styles;

		const isUIReadabilityEnhancementsVariant = useAppSelector(
			isUIReadabilityEnhancementsSelector,
		);

		const shouldShowScannability = useStorefrontFeatureScannability();
		const isBookingTest1 = useStorefrontBookingTest1();
		const trackBookingClick = useTrackBooking(vendor, 'top_navigation_scroll');

		const history = useHistory();

		const goBack = () => {
			if (window.history.length > 1) {
				history.goBack();
			} else {
				history.push('/marketplace');
			}
		};

		return (
			<ul
				style={
					isUIReadabilityEnhancementsVariant
						? { left: 0, ...enhancementStyles }
						: styles
				}
				className={cx(Styles.nav, {
					[Styles.stickyNav]: isSticky,
					[Styles.navHide]: !isSticky,
				})}
				id="nav-bar"
				ref={ref}
			>
				<div className={Styles.navBarContainer}>
					{isUIReadabilityEnhancementsVariant && isSticky && (
						<div className={Styles.vendorContainer}>
							<IconButton
								size="md"
								className={Styles.iconBack}
								aria-label="Go back"
								name="back"
								onClick={goBack}
							/>
							<Subhead as="h4" className={Styles.subhead}>
								{vendor?.name}
							</Subhead>
						</div>
					)}
					<div className={Styles.navItemMenuContainer}>
						<NavItem title="Photos" />
						<NavMenuItems>{children}</NavMenuItems>
					</div>
					{isSticky && (
						<li className={cx(Styles.navItem, Styles.saveAndShare)} key="share">
							{isUIReadabilityEnhancementsVariant && (
								<div className={Styles.conversationButtonContainer}>
									<StartConversation />
								</div>
							)}

							{!isBookingTest1 && (
								<div className={Styles.favoritesButtonContainer}>
									<FavoritesButton membership={membership} vendor={vendor} />
								</div>
							)}
							{!isBookingTest1 &&
							(!shouldShowScannability ||
								isUIReadabilityEnhancementsVariant) ? (
								<SocialSharing
									vendor={vendor}
									viewport={viewport}
									icon="share_ios"
								/>
							) : null}

							{isBookingTest1 && (
								<Button size="md" onClick={trackBookingClick}>
									Book
								</Button>
							)}
						</li>
					)}
				</div>
			</ul>
		);
	},
);
