import { isUIReadabilityEnhancementsSelector } from '@redux/experiments/selectors/ui-readability-enhancements';
import { useAppSelector } from '@redux/hooks';
import { Column } from '@xo-union/tk-component-grid';
import classNames from 'classnames';
import React, { useState } from 'react';
import type { FC } from 'react';
import type { Decorated } from 'types/vendor';
import { AwardItems } from '../../../../components/award-items/AwardItems';
import { DetailAccordionItem } from '../detail';
import ParentStyles from '../styles.scss';
import Styles from './styles.scss';

interface AwardsProps {
	vendor: Decorated;
	carousel?: boolean;
	fullWidth?: boolean;
}

const Awards: FC<AwardsProps> = ({ vendor, carousel, fullWidth = false }) => {
	const [isOpen, setIsOpen] = useState(true);
	const isUIReadabilityEnhancementsVariant = useAppSelector(
		isUIReadabilityEnhancementsSelector,
	);

	const toggleAccordion = () => {
		setIsOpen((prev) => !prev);
	};

	const category = 'Awards and Affiliations';

	return isUIReadabilityEnhancementsVariant ? (
		<div
			className={classNames(
				ParentStyles.detailsContainer,
				ParentStyles.awardsContainer,
				{
					[Styles.fullWidth]: fullWidth,
				},
			)}
		>
			<DetailAccordionItem
				category={category}
				isOpen={isOpen}
				toggleAccordion={() => toggleAccordion()}
			>
				<AwardItems vendor={vendor} />
			</DetailAccordionItem>
		</div>
	) : (
		<Column
			xs="12"
			md={fullWidth ? '12' : '6'}
			className={classNames({ [Styles.fullWidth]: fullWidth })}
		>
			<div
				className={classNames({
					[Styles.awardTitleBold]: fullWidth,
					[Styles.awardTitle]: !fullWidth,
				})}
			>
				Awards and Affiliations
			</div>
			<div className={Styles.awards}>
				<AwardItems vendor={vendor} carousel={carousel} />
			</div>
		</Column>
	);
};

export default Awards;
